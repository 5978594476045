<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <header class="casea">
        <waihead></waihead>
    </header>
</template>

<script>
import waihead from '../Waihead'
export default {
  name: 'casea',
  components: { waihead },
  data () {
    return {
    }
  },
  created () {
  },
  mounted () {
  },
  watch: {
  },
  methods: {
    // 基础信息
    jichu () {
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.casea {
}
</style>
